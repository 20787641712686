import { createSlice } from "@reduxjs/toolkit";
import { isJson } from "../../js/homeActions";

const __default__ = {
  isLoggedIn: false,
  id: null,
  authSession: null,
  user: {
    first_name: null,
    last_name: null,
    email: null,
    username: null,
    type: null,
    status: 0,
    kyc_status: 0,
    kyc_desc: "",
  },
  authScreen: {
    loading: false,
    message: null,
    action: "",
    status: 0,
  },
  sendCode: {
    delay: false,
    timer: 120,
  },
  logout: {
    value: false,
    reload: false,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState: isJson(localStorage.getItem("reduxState")).auth || __default__,
  reducers: {
    getUser: (slice) => {
      // slice = { ...slice, authScreen: { loading: true } };
    },
    setUser: (slice, { payload }) => {
      slice.isLoggedIn = true;
      slice.id = payload.primary_key;
      slice.authSession = payload.data.hash_key;
      slice.user = payload.data;
    },
    setAuth: (slice, { payload }) => {
      slice.id = payload.data.id;
      slice.authSession = payload.data.hash_key;
    },
    register: (slice) => {
      // Turn on loader and disable form before sending registeration data
      slice.authScreen = {
        ...slice.authScreen,
        loading: true,
        message: null,
        status: 0,
      };
    },
    authShowStatus: (slice, { payload: { data } }) => {
      // throw data;
      let info = data.status === undefined ? { status: 0, message: "An Error Occured" } : data;
      slice.authScreen = {
        ...slice.authScreen,
        loading: false,
        message: info.message,
        status: info.status,
        action: data.action,
      };
      if (!data.status) console.error("Error authReducer > authShowStatus", data);
    },
    authHideStatus: (slice) => {
      slice.authScreen = {
        ...slice.authScreen,
        loading: false,
        message: null,
        status: 0,
      };
    },
    login: (slice) => {
      slice.authScreen = {
        ...slice.authScreen,
        loading: true,
        message: null,
        status: 0,
      };
    },
    logout: (slice) => {
      slice.logout = { value: true, reload: true };
    },
    sendCode: (slice, { payload }) => {
      slice.sendCode = { ...slice.sendCode, delay: true };
    },
    updateTimer: (slice, { payload }) => {
      slice.sendCode = { ...slice.sendCode, timer: payload };
    },
    unFreezeSendCode: (slice) => {
      slice.sendCode = { timer: __default__.sendCode.timer, delay: false };
    },
    confirmResetPasswordAccount: (slice) => {
      slice.authScreen = { ...slice.authScreen, loading: true };
    },
    resetPassword: (slice) => {
      slice.authScreen = {
        ...slice.authScreen,
        loading: true,
        message: null,
        status: 0,
      };
    },
    confirmEmail: (slice) => {
      slice.authScreen = {
        ...slice.authScreen,
        loading: true,
      };
    },
    sendSeed: (slice) => {
      slice.authScreen = {
        ...slice.authScreen,
        loading: true,
      };
    },
    uploadKYC: (slice) => {
      slice.authScreen = {
        ...slice.authScreen,
        loading: true,
        message: null,
        status: 0,
      };
    },
  },
});

export const {
  getUser,
  uploadKYC,
  setUser,
  register,
  authShowStatus,
  authHideStatus,
  logout,
  login,
  sendCode,
  resetPassword,
  confirmEmail,
  unFreezeSendCode,
  confirmResetPasswordAccount,
  updateTimer,
  setAuth,
  sendSeed,
} = authSlice.actions;
export default authSlice.reducer;
