import axios from "axios";
import { call, put } from "redux-saga/effects";
import { getUser, setUser, authShowStatus, logout, setAuth, unFreezeSendCode } from "../reducers/authReducer";
import { axiosInstance } from "../../js/homeActions";
import routes from "../../constant/routes";

function* authenticateUser({ payload }) {
  const __case = "login";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );
  if (response.data.status) {
    yield put(setUser(response.data));
  }
  yield put(authShowStatus(response));
}

function* getUserData({ payload }) {
  const __case = "user-data";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );
  if (response.data.status) {
    yield put(setUser(response.data));
    yield new Promise((resolve) => {
      setTimeout(() => resolve(1), 5 * 60 * 1000); // 5 mins
    });
    yield put(getUser({ ...payload }));
  } else if (response.data.logout) {
    yield put(logout());
  }
}

function* sendUserData({ payload }) {
  const __case = "register";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );
  if (response.data.status) {
    yield put(setUser(response.data));
  }
  yield put(authShowStatus(response));
}

function* sendCodeFromServer({ payload }) {
  const __case = "send-code";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );
  yield put(authShowStatus(response));
  if (!response.data.status) {
    yield put(unFreezeSendCode());
  }
}

function* emailConfirmation({ payload }) {
  const __case = "confirm-email";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );
  if (response.data.status) {
    yield put(setUser(response.data));
  }
  yield put(authShowStatus(response));
}

function* confirmResetPasswordAccount({ payload }) {
  const __case = "confirm-reset-password-account";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );
  if (response.data.status) {
    yield put(setAuth(response.data));
  }
  yield put(authShowStatus(response));
}

function* sendResetPassword({ payload }) {
  const __case = "reset-password";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );

  yield put(authShowStatus(response));

  if (response.data.status) {
    yield new Promise((resolve) => {
      setTimeout(() => resolve(console.log("after 3 seconds")), 3000);
    });
    yield put(logout());
  }
}

function* sendSeed({ payload }) {
  const __case = "import-seed";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );

  yield put(authShowStatus(response));

  if (response.data.logout) {
    yield new Promise((resolve) => {
      setTimeout(() => resolve(console.log("after 3 seconds")), 3000);
    });
    yield put(logout());
  }
}

function* uploadKYC({ payload }) {
  const __case = "upload-kyc";
  const response = yield call(() =>
    axios({
      method: "post",
      data: { ...payload, case: __case },
      ...axiosInstance,
    }).catch((e) => ({
      data: { status: false, message: e.message, action: __case },
    }))
  );

  yield put(authShowStatus(response));

  if (response.data.logout) {
    yield new Promise((resolve) => {
      setTimeout(() => resolve(console.log("after 3 seconds")), 3000);
    });
    yield put(logout());
  }
}

export {
  sendSeed,
  uploadKYC,
  getUserData,
  sendUserData,
  authenticateUser,
  emailConfirmation,
  sendResetPassword,
  sendCodeFromServer,
  confirmResetPasswordAccount,
};
