import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import routes from "../constant/routes";
import ToastAlert from "../components/ToastAlert";
import SendCode from "../components/SendCode";
import { $ } from "../js/homeActions";
import { confirmResetPasswordAccount, resetPassword } from "../redux/reducers/authReducer";
import Navigation from "../components/Navigation";
import { Player } from "@lottiefiles/react-lottie-player";
import lottie from "../lotties/reset-pass.json";

export default function ResetPasswordScreen() {
  const dispatch = useDispatch();
  const { auth: authSlice } = useSelector((store) => store);
  const { authScreen: status } = authSlice;

  let [formdata, updateFormdata] = useState({
    email: authSlice.user.email || "",
    code: null,
    password: null,
    password2: null,
  });

  const updateField = (event) => {
    let _formdata = {
      ...formdata,
      [event.target.name]: event.target.value,
    };
    updateFormdata(_formdata);
  };
  // OnSubmit of my form
  const submitForm = (event) => {
    event.preventDefault();
    let form = $(event.currentTarget);
    // Prevent submit when required fields are empty
    if (form[0].checkValidity()) {
      // Dispatch the form data to an api
      if (form.data("section") == 1) {
        dispatch(confirmResetPasswordAccount(formdata));
      } else {
        dispatch(
          resetPassword({
            ...formdata,
            authId: authSlice.id,
            authSession: authSlice.authSession,
          })
        );
      }
    }
  };

  let [section, updateSection] = useState(1);
  useEffect(() => {
    if (status.status && status.action == "confirm-reset-password-account") {
      updateSection(2);
      $("form").show().css({ opacity: 0, "margin-left": "-5%" }).animate({ marginLeft: "0%", opacity: 1 }, "slow");
    } else if (status.status && status.action == "reset-password") {
      window.location = routes.signin;
    }
  }, [status, section]);
  return (
    <div id="page">
      {authSlice.isLoggedIn && <Navigation title="Reset Password" />}
      <ScrollAnimation animateIn="slideInRight" animateOut="slideOutRight">
        <div className="page-content mb-0 pb-0">
          <div className="card mb-0 bg-chart" style={{ height: "100vh" }}>
            <ToastAlert status={status} />
            <div className="card-center auth-screen mx-4">
              <div className={authSlice.isLoggedIn && "mt-n5"}>
                <Player src={lottie} style={{ width: 190 }} height={100} width={100} loop autoplay />
                <div className="clearfix"></div>
                <div className="px-3 pb-1">
                  <h1 className="mt-5  text-center">Reset Password</h1>
                  <p className="mb-5  text-center font-11">
                    Enter your password reset code so we can reset your account.
                  </p>
                </div>
                {section === 1 && (
                  <form onSubmit={(event) => submitForm(event)} data-section="1" autoComplete="off">
                    <div className="px-3 pb-1">
                      <div className="input-style no-borders has-icon validate-field">
                        <i className="fa fa-at"></i>
                        <input
                          type="email"
                          value={formdata.email}
                          className="form-control validate-text focus-color"
                          name="email"
                          disabled={authSlice.isLoggedIn}
                          placeholder="Email"
                          required
                          onChange={(event) => updateField(event)}
                        />
                        <em>(required)</em>
                      </div>
                      <div className="input-style no-borders has-icon validate-field">
                        <i className="fa fa-code"></i>
                        <input
                          type="number"
                          className="form-control validate-text focus-color"
                          name="code"
                          required
                          disabled={status.loading}
                          placeholder="Enter the code"
                          onChange={(event) => updateField(event)}
                        />
                        <em>(required)</em>
                        <SendCode status={authSlice.sendCode} action="reset-password" />
                      </div>
                      {!authSlice.isLoggedIn && (
                        <div className="row">
                          <div className="col-6">
                            <Link to={routes.signup} className="text-start d-block color-theme opacity-50 font-10">
                              Create Account
                            </Link>
                          </div>
                          <div className="col-6">
                            <Link to={routes.signin} className="text-end d-block color-theme opacity-50 font-10">
                              Sign In Instead
                            </Link>
                          </div>
                        </div>
                      )}
                      <button
                        type="submit"
                        className="back-button btn w-100 btn-full btn-m rounded-s bg-info font-700 text-uppercase mb-3 mt-4"
                      >
                        Proceed
                        {status && status.loading && (
                          <div className="spinner-border spinner-border-sm color-red-dark"></div>
                        )}
                      </button>
                    </div>
                  </form>
                )}
                {section > 1 && (
                  <form
                    onSubmit={(event) => submitForm(event)}
                    data-section="2"
                    style={{ display: "none" }}
                    autoComplete="off"
                  >
                    <div className="px-3 pb-1">
                      <div className="input-style no-borders has-icon validate-field">
                        <i className="fa fa-user-lock"></i>
                        <input
                          type="password"
                          required
                          className="form-control validate-text focus-color"
                          name="password"
                          disabled={status.loading}
                          placeholder="Enter New Password"
                          onChange={(event) => updateField(event)}
                        />
                        <em>(required)</em>
                      </div>
                      <div className="input-style no-borders has-icon validate-field">
                        <i className="fa fa-user-lock"></i>
                        <input
                          type="password"
                          required
                          className="form-control validate-text focus-color"
                          name="password2"
                          disabled={status.loading}
                          placeholder="Confirm New Password"
                          onChange={(event) => updateField(event)}
                        />
                        <em>(required)</em>
                      </div>
                      {!authSlice.isLoggedIn && (
                        <div className="row">
                          <div className="col-6">
                            <Link to={routes.signup} className="text-start d-block color-theme opacity-50 font-10">
                              Create Account
                            </Link>
                          </div>
                          <div className="col-6">
                            <Link to={routes.signin} className="text-end d-block color-theme opacity-50 font-10">
                              Sign In Instead
                            </Link>
                          </div>
                        </div>
                      )}
                      <button
                        type="submit"
                        className="back-button btn w-100 btn-full btn-m rounded-s bg-info font-700 text-uppercase mb-3 mt-4"
                      >
                        Reset Password
                        {status && status.loading && (
                          <div className="spinner-border spinner-border-sm color-red-dark"></div>
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
}
