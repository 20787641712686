import $ from "jquery";
import React, { useEffect } from "react";
import {
  sendCode,
  unFreezeSendCode,
  authShowStatus,
  updateTimer,
} from "../redux/reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";

export default function SendCode({ action, status }) {
  let dispatch = useDispatch();
  let authSlice = useSelector((store) => store.auth);

  useEffect(() => {
    if (status.delay) {
      let interval = setTimeout(() => {
        dispatch(updateTimer(status.timer - 1));
        if (status.timer === 0) {
          dispatch(unFreezeSendCode());
        }
      }, 1000);
      return () => clearTimeout(interval);
    }
  });
  return (
    <button
      type="button"
      data-action={action}
      className="btn bg-info btn-send-code"
      onClick={(event) => {
        let button = $(event.currentTarget);
        let email = undefined;
        if (button.closest("form").data("section")) {
          const form = button.closest("form");
          email = form.find("input[type=email]").val();
          if (!email) {
            form.find("input[type=email]").focus();
            dispatch(
              authShowStatus({
                data: { status: 0, message: "Enter Email to proceed" },
              })
            );
            return;
          }
        }

        dispatch(
          sendCode({
            authorization: email,
            action,
            authId: authSlice.id,
            authSession: authSlice.user.hash_key,
          })
        );
      }}
      disabled={status.delay}
    >
      {status && status.delay && `${status.timer}s`}
      {!status.delay && "Send Code"}
      {status && status.delay && (
        <div className="spinner-border ms-4 spinner-border-sm color-red-dark"></div>
      )}
    </button>
  );
}
