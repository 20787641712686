import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Carousel from "react-elastic-carousel";
import ScrollAnimation from "react-animate-on-scroll";
import { isBrowser, isAndroid, isIOS } from "react-device-detect";
import { Player } from "@lottiefiles/react-lottie-player";

// Custom Imports
import { $ } from "../js/homeActions";
import routes from "../constant/routes";
import { openSheet } from "../js/homeActions";

import rocket from "../lotties/93413-rocket-purple.json";
import support from "../lotties/41180-customer-service-support-agent-animation.json";
import download from "../lotties/lf30_editor_pichh2dn.json";

export default function StartScreen() {
  const { company } = useSelector((store) => store);

  return (
    <div id="page">
      <ScrollAnimation animateIn="slideInRight" animateOut="slideOutRight">
        <div className="page-content m-0 p-0">
          <div className="cover-slider slider-no-arrows slider-no-dots" id="cover-slider-1">
            <Carousel>
              <div className="slide-carousel">
                <div data-card-height="cover" className="card m-0 rounded-0">
                  <div className="card-center">
                    <img src={company.favicon} alt="img" width="160" className="mx-auto mb-3" />
                    <h1 className="text-center mb-0 font-700 font-25">Welcome to {company.name}</h1>
                    <p className="boxed-text-xl opacity-80 font-14">
                      A Decentralized Wallet made for your safety and unique experience.
                      <br />
                      <br />
                      We have created this path to ease your way into the crypto space.
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide-carousel">
                <div data-card-height="cover" className="card m-0 rounded-0">
                  <div className="card-center">
                    <Player src={rocket} style={{ width: 260 }} height={160} width={160} loop autoplay />
                    <h1 className="text-center mb-0 font-700 font-25">Runs on Rocket Fuel</h1>
                    <p className="boxed-text-xl opacity-80">
                      We make use of the worlds biggest frameworks and make our products better.
                      <br />
                      <br />
                      You would be promted to install this app on your home screen to optimize speed.
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide-carousel">
                <div data-card-height="cover" className="card m-0 rounded-0">
                  <div className="card-center">
                    <Player src={support} style={{ width: 260 }} height={160} width={160} loop autoplay />
                    <h1 className="text-center mb-0 font-700 font-25">We're always here for you!</h1>
                    <p className="boxed-text-xl opacity-80">
                      We're here to help you make your product and your dream come true. Read the docs to write a
                      ticket.
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide-carousel">
                <div data-card-height="cover" className="card m-0 rounded-0">
                  <div className="card-center">
                    <Player src={download} style={{ width: 260 }} height={160} width={160} loop autoplay />

                    <h1 className="text-center mb-0 font-700 font-25">Install this App</h1>
                    <h6 className="text-center font-500 font-12 mb-3 text-info">On your mobile screen.</h6>
                    {isAndroid && (
                      <p className="boxed-text-xl opacity-80">
                        To install {company.name} on your home screen and access it just like a regular app, Open your
                        browser menu and tap "Add to Home Screen".
                      </p>
                    )}
                    {isBrowser && (
                      <p className="boxed-text-xl opacity-80">
                        To install {company.name} on your pc, <br /> Open this page on Chrome, go to menu, click on more
                        tools then create shortcut.
                      </p>
                    )}
                    {isIOS && (
                      <p className="boxed-text-xl opacity-80">
                        To install {company.name} on your home screen and access it just like a regular app, Open your
                        Safari, click on share icon and add to Home Screen.
                      </p>
                    )}
                  </div>
                  <button
                    onClick={(event) => {
                      openSheet(event, $);
                    }}
                    data-open="menu-option-1"
                    className="slider-bottom-button btn w-100 btn-m bg-blue-dark btn-full mx-2 shadow-xl font-700 text-uppercase show-offline"
                  >
                    Yes I have installed it.
                  </button>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </ScrollAnimation>
      <div id="menu-option-1" className="menu menu-box-bottom" data-menu-height="190">
        <div className="menu-title">
          <h1 className="my-0 py-0">How do you want to proceed ?</h1>
        </div>
        <div className="content mt-n3">
          <p className="pe-3">Login to your {company.name} account or import an external wallet.</p>
          <div className="row mb-0">
            <div className="col-6">
              <Link to={routes.import} className="btn close-menu btn-full btn-m bg-info font-800 text-uppercase">
                Import
              </Link>
            </div>
            <div className="col-6">
              <Link to={routes.signin} className="btn close-menu btn-full btn-m bg-blue-dark font-800 text-uppercase">
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
