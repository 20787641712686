import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFileUpload from "react-use-file-upload";

import style from "../styles/Dispute.module.css";
import ToastAlert from "./ToastAlert";
import { getUser, uploadKYC } from "../redux/reducers/authReducer";

export default function KYCForm() {
  const inputRef = useRef();
  const dispatch = useDispatch();

  const {
    auth: {
      id,
      authSession,
      authScreen: { loading, action, status },
    },
    company,
  } = useSelector((store) => store);

  useEffect(() => {
    if (action === "upload-kyc") {
      dispatch(getUser({ authId: id, authSession: authSession }));
    }
  }, [action]);

  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const promises = files.map((file) => toBase64(file));
    const formdata = await Promise.all(promises);
    if (formdata.length) {
      dispatch(uploadKYC({ formdata, authId: id, authSession: authSession }));
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return (
    <div>
      <ToastAlert status={{ loading, action, status }} />
      <form action="" onSubmit={handleSubmit} className="pt-2">
        {files.length < 2 && (
          <div className="upload">
            <span>
              <i>Upload Documents (not more than 2 files)</i>
            </span>
            <div
              className={style.dropzone}
              onDragEnter={handleDragDropEvent}
              onDragOver={handleDragDropEvent}
              onClick={() => inputRef.current.click()}
              onDrop={(e) => {
                handleDragDropEvent(e);
                setFiles(e, "a");
              }}
            >
              <span className="fade1 bg-grey1">
                <span className="fade2 bg-grey2">
                  <i className="fas fa-cloud-upload-alt"></i>
                </span>
              </span>
              <span>
                <i className="clickable">
                  Click to upload
                  <input
                    ref={inputRef}
                    type="file"
                    accept="image/*"
                    multiple
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setFiles(e, "a");
                      inputRef.current.value = null;
                    }}
                  />
                </i>{" "}
                or drag and drop <br />
                SVG, PNG, JPG, GIFF <span> (max,800x,400px) </span>
              </span>
            </div>
          </div>
        )}
        {files.length > 0 && (
          <p className="d-flex justify-content-between mt-3">
            <span onClick={clearAllFiles} className="clickable">
              <i className="fas fa-times me-2"></i>
              Clear All
            </span>
            <span>Total Size: {totalSize}</span>
          </p>
        )}
        <section className="divs3">
          {fileNames.map((name, index) => (
            <div key={index} className={style.uploadedfile}>
              <span className="fade1 bg-purple1">
                {files[index] && (
                  <figure>
                    <img src={URL.createObjectURL(files[index])} alt="" />
                  </figure>
                )}
              </span>
              <div>
                <p>
                  <span>{name}</span>
                  <i onClick={() => removeFile(name)} className="far fa-trash-alt clickable" aria-hidden="true"></i>
                </p>
                {files[index] && <p>{(files[index].size / 1000).toFixed(2)} KB</p>}
                <div className={style.status}>
                  <div className={style.progress}></div>
                  <span>100%</span>
                </div>
              </div>
            </div>
          ))}
        </section>
        {files.length === 2 && (
          <div className="footer-nav-buttons justify-end">
            <button className="btn btn-warning d-flex align-items-center rounded gap-2">
              <span>Submit</span>
              {loading && <div className="spinner-border spinner-border-sm text-default ms-2"></div>}
              {!loading && <i className="far fa-check-circle me-2"></i>}
            </button>
          </div>
        )}
      </form>
    </div>
  );
}
