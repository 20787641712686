const routes = {
  index: "/",
  start: "/",
  notFound: "*",
  coin: "/coin",
  swap: "/swap",
  wallet: "/app",
  status: "/status",
  signup: "/sign-up",
  signin: "/sign-in",
  signout: "/sign-out",
  import: "/import-wallet",
  confirmEmail: "/confirm-email",
  resetPassword: "/reset-password",
  apiRoute:
    process.env.NODE_ENV === "development"
      ? "http://localhost/lendtrx/backend/process/custom"
      : "https://mgt.passive.digital/process/custom",
};
export default routes;
