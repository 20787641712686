import React, { useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ScrollAnimation from "react-animate-on-scroll";
import routes from "../constant/routes";
import { login } from "../redux/reducers/authReducer";
import { $ } from "../js/homeActions";
import ToastAlert from "../components/ToastAlert";
import lottie from "../lotties/88252-data-security.json";

export default function SigninScreen() {
  const dispatch = useDispatch();
  const {
    company,
    auth: { authScreen: status },
  } = useSelector((store) => store);

  // Manage internal state of my formdata locally
  const [formdata, updateFormdata] = useState({
    password: null,
    email: null,
  });

  // Update the formdata state locally
  const updateField = (event) => {
    let _formdata = {
      ...formdata,
      [event.target.name]: event.target.value,
    };
    updateFormdata(_formdata);
  };

  // OnSubmit of my form
  const submitForm = (event) => {
    event.preventDefault();
    let form = $(event.currentTarget);
    // Prevent submit when required fields are empty
    if (form[0].checkValidity()) {
      // Dispatch the form data to an api
      dispatch(login(formdata));
    }
  };

  return (
    <div id="page">
      <ScrollAnimation animateIn="slideInRight" animateOut="slideOutRight">
        <div className="page-content mb-0 pb-0">
          <div className="card mb-0 bg-chart" style={{ height: "100vh" }}>
            <ToastAlert status={status} />
            <div className="card-center auth-screen mx-4">
              <div className="">
                <Player src={lottie} style={{ width: 260 }} height={100} width={100} loop autoplay />

                <div className="clearfix"></div>
                <form
                  autoComplete="off"
                  onSubmit={(event) => {
                    submitForm(event);
                  }}
                >
                  <div className="px-3 pb-1">
                    <h1 className="mt-2 mb-n1 text-center">Sign In</h1>
                    <p className="mb-5 font-11 text-center">Hello Stranger, Please enter your credentials below.</p>
                    <div className="input-style no-borders has-icon validate-field">
                      <i className="fa fa-user"></i>
                      <input
                        type="email"
                        name="email"
                        autoComplete="off"
                        className="form-control focus-color validate-name"
                        required
                        disabled={status.loading}
                        onChange={(event) => {
                          updateField(event);
                        }}
                        placeholder="Your Email"
                      />
                      <label htmlFor="form1a" className="color-blue-dark font-10 mt-1">
                        Name
                      </label>
                      <i className="fa fa-times disabled invalid color-red-dark"></i>
                      <i className="fa fa-check disabled valid color-green-dark"></i>
                      <em>(required)</em>
                    </div>
                    <div className="input-style no-borders has-icon validate-field mt-4">
                      <i className="fa fa-lock"></i>
                      <input
                        autoComplete="off"
                        type="password"
                        name="password"
                        className="form-control focus-color validate-password"
                        required
                        disabled={status.loading}
                        onChange={(event) => {
                          updateField(event);
                        }}
                        placeholder="Password"
                      />
                      <label htmlFor="form3a" className="color-blue-dark font-10 mt-1">
                        Password
                      </label>
                      <i className="fa fa-times disabled invalid color-red-dark"></i>
                      <i className="fa fa-check disabled valid color-green-dark"></i>
                      <em>(required)</em>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <Link to={routes.signup} className="text-start d-block color-theme opacity-50 font-10">
                          Create Account
                        </Link>
                      </div>
                      <div className="col-4">
                        <Link
                          to={routes.import}
                          className="text-start text-center d-block color-theme opacity-50 font-10"
                        >
                          Import Seed
                        </Link>
                      </div>
                      <div className="col-4">
                        <Link to={routes.resetPassword} className="text-end d-block color-theme opacity-50 font-10">
                          Forgot Password
                        </Link>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="back-button btn btn-full w-100 btn-m rounded-s bg-blue-dark font-700 text-uppercase mb-3 mt-4"
                    >
                      <span className="me-3">Sign In</span>
                      {status && status.loading && (
                        <div className="spinner-border spinner-border-sm color-red-dark"></div>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
}
