import React from "react";
import { useSelector } from "react-redux";

export default function KYCStatus() {
  const { auth, company } = useSelector((store) => store);

  const status = {
    0: { icon: "fas fa-exclamation-circle", color: "warning" },
    1: { icon: "far fa-check-circle", color: "success" },
    2: { icon: "fas fa-ban", color: "danger" },
  };

  return (
    <div>
      {auth.user.kyc_status === -1 && (
        <div className="text-center">
          <h5>
            <i className="fas fa-exclamation-circle me-2 text-warning"></i>Your profile has a pending KYC form.
          </h5>
          <p>
            <small>
              Upload a clear passport photograph and a valid ID Card (National ID, Drivers Licence, Military ID)
            </small>
          </p>
        </div>
      )}
      {auth.user.kyc_status > -1 && (
        <div className="text-center">
          <h4>KYC Status</h4>
          <figure className="mt-4">
            <i
              className={`${status[auth.user.kyc_status].icon} text-${status[auth.user.kyc_status].color} me-2`}
              style={{ fontSize: 75 }}
            ></i>
          </figure>
          <h5>Your KYC is {auth.user.kyc_desc}</h5>
        </div>
      )}
    </div>
  );
}
