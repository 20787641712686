import React from "react";
import { useSelector } from "react-redux";

export default function CryptoChart() {
  const {
    coin: { data: coin },
  } = useSelector((store) => store);
  return (
    <>
      <div
        id="CryptoChart"
        className="menu menu-box-bottom"
        style={{ height: "80vh", backgroundColor: "#1D2330" }}
      >
        {/* <TradeViewChart pair="BTCBUSD" /> */}
        <div id="chart-container">
          <div style={{ height: "100%", padding: 0, margin: 0, width: "100%" }}>
            <iframe
              title="BTC"
              src={`https://widget.coinlib.io/widget?type=chart&theme=dark&coin_id=${coin.graph_id}&pref_coin_id=1505`}
              width="100%"
              height="536px"
              scrolling="auto"
              marginWidth="0"
              marginHeight="0"
              frameBorder="0"
              border="0"
              style={{ border: 0, margin: 0, padding: 0, lineHeight: 14 }}
            ></iframe>
          </div>
        </div>
      </div>
      <div className="menu-hider menu-active"></div>
    </>
  );
}
