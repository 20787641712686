const SW = {
  register: () => {
    if ("serviceWorker" in navigator) {
      const env = new URLSearchParams({
        BROSWER_CACHE: process.env.REACT_APP_BROSWER_CACHE,
      }).toString();

      // Service worker URL w/config variables
      navigator.serviceWorker
        .register(`${process.env.PUBLIC_URL}service-worker.js?${env}`, {
          scope: ".", // <--- THIS BIT IS REQUIRED
        })
        .then(
          function (registration) {
            // Registration was successful
            console.log("ServiceWorker registration successful with scope: ", registration.scope);
          },
          function (err) {
            // registration failed :(
            console.log("ServiceWorker registration failed: ", err);
          }
        );
    }
  },
};

export default SW;
