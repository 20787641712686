import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useDispatch, useSelector } from "react-redux";
//  Components
import Navigation from "../components/Navigation";
import ToastAlert from "../components/ToastAlert";
import Select, { components } from "react-select";
import { num_format, $ } from "../js/homeActions";
import { getWallet } from "../redux/reducers/walletReducer";
import { showStatus, swapAssets, getExchangeTransactions } from "../redux/reducers/swapReducer";
import routes from "../constant/routes";
import { Link } from "react-router-dom";
export default function SwapSCreen() {
  const { Option } = components;
  const dispatch = useDispatch();
  // Redux Store
  const {
    swap,
    wallet: { assets },
    auth,
  } = useSelector((store) => store);
  // Swap State
  const [swapState, setSwapState] = useState({ ...swap, reload: false });
  const [formdata, updateFormdata] = useState({
    coinA: null,
    coinB: null,
    amount: "0.1",
    value: "0.0",
    authId: auth.id,
    authSession: auth.authSession,
  });

  //Get coins List
  const coins = assets
    .filter((coin) => coin.hasWallet)
    .map((coin) => {
      return {
        decimal: coin.decimals,
        value: coin.symbol,
        label: coin.symbol,
        icon: coin.logo,
        balance: parseFloat(coin.balance),
        price: parseFloat(coin.price),
      };
    });

  // Function that builds a list of coins with their logos
  const IconOption = (props) => (
    <Option {...props}>
      <img src={props.data.icon} className="me-2" style={{ width: 15 }} alt={props.data.label} />
      {props.data.label}
    </Option>
  );

  // change inject icon to a chosen coin
  const injectIcon = (data, element) => {
    const { name } = element;
    $(`#${element.name}`)
      .find(".css-qc6sy-singleValue")
      .empty()
      .append($("<img>").attr({ src: data.icon }).css({ width: 15, marginRight: 5 }))
      .append(data.label);

    setSwapState({ ...swapState, reload: !swapState.reload, [name]: data });
    updateFormdata({
      ...formdata,
      value: getConversion(swapState.coinA, swapState.coinB),
    });
  };

  // function that converts currencys
  const getConversion = (__a, __b) => {
    const dollarPriceA = __a.price * formdata.amount;
    const value = dollarPriceA / __b.price;
    return value.toFixed(__b.decimal);
  };

  // setintervals for getting wallet coins and exchange transactions
  useEffect(() => {
    const { id, authSession } = auth;
    dispatch(getWallet({ authId: id, authSession }));
    dispatch(getExchangeTransactions({ authId: id, authSession }));

    const interval = setInterval(() => {
      dispatch(getWallet({ authId: id, authSession }));
      dispatch(getExchangeTransactions({ authId: id, authSession }));
    }, 60 * 1000); // 1 min
    return () => {
      clearInterval(interval);
    };
  }, []);

  // build and rebuild coinA coinB
  useEffect(() => {
    const listA = coins.filter((coin) => coin.balance > 0);
    const coinA = swapState.coinA ? swapState.coinA : listA.length ? listA[0] : undefined;
    const listB = coins.filter((coin) => !coinA || coinA.value !== coin.value);

    const coinB = swapState.coinB ? swapState.coinB : listB.length ? listB[0] : undefined;

    setSwapState({
      ...swapState,
      coinA,
      coinB,
      listA,
      listB,
    });
    if (coinA && coinB) {
      updateFormdata({
        ...formdata,
        coinA: coinA.value,
        coinB: coinB.value,
        value: getConversion(coinA, coinB),
      });
    }
  }, [swapState.reload, formdata.amount]);

  // Submit Form
  const submitForm = (event) => {
    event.preventDefault();
    let form = $(event.currentTarget);
    // Prevent submit when required fields are empty
    if (form[0].checkValidity()) {
      // Dispatch the form data to an api
      // dispatch(register(formdata));
      if (!formdata.coinA || !formdata.coinB) {
        dispatch(
          showStatus({
            data: {
              ...swap.status,
              status: false,
              message: "Operation not permitted",
            },
          })
        );
      } else if (formdata.coinA === formdata.coinB) {
        dispatch(
          showStatus({
            data: {
              ...swap.status,
              status: false,
              message: "You can not swap identical assets",
            },
          })
        );
      } else {
        const { id, authSession } = auth;
        dispatch(swapAssets(formdata));
        setTimeout(() => {
          dispatch(getWallet({ authId: id, authSession }));
          dispatch(getExchangeTransactions({ authId: id, authSession }));
        }, 2000);
      }
    }
  };
  return (
    <div id="page">
      <ToastAlert status={swap.status} reducer="swap" />
      <Navigation title="Exchange" />
      <div className="pull-up">
        <i className="fa fa-exchange-alt me-3"></i> <strong>Exchange</strong>
      </div>
      {formdata.coinA && (
        <ScrollAnimation animateIn="slideInUp" animateOut="slideOutDown">
          <div className="page-content" style={{ height: "85vh" }}>
            <div style={{ height: 250 }} className="card mx-3 rounded-sm">
              <form
                onSubmit={(event) => {
                  submitForm(event);
                }}
              >
                <div className="row mb-0">
                  <div className="col-7">
                    <div className="input-style w-100 has-borders has-icon validate-field mb-0">
                      <i className="fa fa-info-circle"></i>
                      <input
                        type="amount"
                        className="form-control validate-name"
                        type="number"
                        required
                        step="any"
                        min={formdata.amount}
                        defaultValue={formdata.amount}
                        placeholder="Enter Amount"
                        onChange={(event) => {
                          updateFormdata({
                            ...formdata,
                            amount: event.target.value,
                          });
                        }}
                      />
                      <em>(required)</em>
                    </div>
                  </div>
                  <div className="col-5">
                    <div id="coinA" className="input-style has-borders no-icon mb-0">
                      {swapState.coinA && (
                        <Select
                          name="coinA"
                          id="SelectcoinA"
                          defaultValue={swapState.coinA}
                          options={swapState.listA}
                          components={{ Option: IconOption }}
                          onChange={(data, element) => {
                            setTimeout(() => {
                              injectIcon(data, element);
                            }, 200);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-0">
                  <div className="col-12">
                    {swapState.coinA && (
                      <div className="boxed-swap-details">
                        <p className="mt-0">
                          <small>Available</small>: {num_format(swapState.coinA.balance)} {swapState.coinA.label}
                        </p>
                        <p className="my-4">
                          <small>
                            <span>
                              <strong className="text-white">1 </strong> <i>{swapState.coinA.label}</i>
                            </span>
                            <span> = </span>
                            <strong className="text-white">{num_format(swapState.coinA.price)}</strong> USDT
                          </small>
                        </p>
                        <p className="mb-0">
                          <small>Estimated</small>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-7">
                    <div className="input-style w-100 has-borders has-icon validate-field mb-4">
                      <i className="fa fa-dollar-sign"></i>
                      <input
                        type="text"
                        className="form-control validate-name"
                        value={num_format(formdata.value)}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-5">
                    <div id="coinB" className="input-style has-borders no-icon mb-4">
                      {swapState.coinB && (
                        <Select
                          name="coinB"
                          defaultValue={swapState.coinB}
                          options={swapState.listB}
                          components={{ Option: IconOption }}
                          onChange={(data, element) => {
                            setTimeout(() => {
                              injectIcon(data, element);
                            }, 200);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-m w-100 mb-3 rounded text-uppercase font-900 shadow-s bg-blue-dark"
                    >
                      <i className="fa fa-exchange-alt me-3"></i>
                      <strong>Swap Tokens</strong>
                      {swap.status && swap.status.loading && (
                        <div className="spinner-border spinner-border-sm text-default ms-3"></div>
                      )}
                    </button>
                  </div>
                </div>
              </form>
              {/* Swap History Transactions */}
              <div className="container-fluid">
                <p className="text-center">
                  <small>
                    Show History <br /> <i className="fa fa-arrow-down"></i>
                  </small>
                </p>
                <div className="row">
                  {/* ---------------------------Transactons--------------------------------- */}
                  <div className="collapse show col-12" id="tab-1">
                    <div className="list-group list-custom-large">
                      {swap.transactions &&
                        swap.transactions.length > 0 &&
                        swap.transactions.map((transaction) => (
                          <a key={transaction.tx_no} className="coin-item bordered mt-0" style={{ height: 59 }}>
                            <div className="w-15">
                              <i className={`fa fa-exchange-alt font-20 text-success`}></i>
                            </div>
                            <div className="w-80">
                              <p className="mb-1">
                                <b className="text-white font-15">
                                  {transaction.coin} {"   ===>   "}
                                  {transaction.address}
                                </b>
                                <small className="">
                                  {transaction.amount} {transaction.address}
                                </small>
                              </p>
                              <p className="mt-2">
                                <span>
                                  <small className="font-10">{transaction.date_created}</small>
                                </span>
                                <small> {transaction.dollar_value}</small>
                              </p>
                            </div>
                          </a>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      )}
      {!formdata.coinA && (
        <div className="content">
          <div
            data-gallery="gallery-1"
            className="card rounded-s mx-0 shadow-xl"
            style={{
              backgroundImage: "url(assets/images/pictures/chart.jpeg)",
              height: 300,
            }}
            data-card-height="300"
          >
            <div className="card-bottom">
              <h2 className="text-center">Swap is unavailable for you</h2>
            </div>
            <div className="card-overlay bg-gradient-overlay rounded-s"></div>
          </div>

          <p className="boxed-text-xl">
            To be able to perform swap on your assets, you need to have at least 2 active wallets and must have
            deposited some money into any crypto asset of your choice. <br />
            Click the button below to create wallets.
          </p>
          <Link to={routes.wallet} className="btn btn-m btn-center-m btn-warning  text-uppercase font-700">
            Proceed
          </Link>
        </div>
      )}
    </div>
  );
}
